import React from "react"
import {LayoutComponent} from "../components/layout"

const View = () =>(
    <LayoutComponent>
        <h1>Die angegebene Seite wurde nicht gefunden!</h1>
    </LayoutComponent>
);

export default View;

